import * as React from "react";
import { Link } from "gatsby";

// Import layout
import Layout from "../layouts/main";

// Import components
import MetaData from "../components/MetaData";

// Import styles
import "./styles/thank-you.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

// Bootstrap imports
import { Button, Container } from "react-bootstrap";

const ThankYouPage = () => {
  return (
    <Layout>
      <MetaData
        title="Thank You | Flint"
        description="Thanks for submitting our form! We'll get back to you as soon as we can."
        image={`https://flint-fire-starter.netlify.app${image}`}
      />
      <div className="thank-you">
        <Container>
          <div className="thank-you-intro">
            <h1 className="shout">Thank You!</h1>
          </div>
          <div className="thank-you-body">
            <p className="talk">
              Thanks for submitting our form! We'll get back to you as soon as
              we can.
            </p>
            <Link to="/">
              <Button variant="accent">Back to home</Button>
            </Link>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
